import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

const AboutPage = ({ data: { site } }) => {
  return (
    <Layout pageTitle="about">
      <div className="blog-post-container">
        <article className="post">
          <div className="blog-post-content">
            <h1 className="brand-heading">hey there,</h1>
            <p>
              My name is alex and these are my posterity notes. If you landed
              here, I want to personally thank you for reading the lines I
              write. I mainly write for myself, with no other reason, as I feel
              that I have too many thoughts in my head, that I need to get out.
              Some of my writings may be of use for you, if so, feel free to use
              them and evolve them to become better. I seek for a better world,
              professionally and personally. I am a responsibility junkie, and
              for me, it means that I am going to get hurt from time to time.
              But that is fine, I live in a fantastical world that has an
              imagined absurd reality, full of philosophic debate, challenges,
              improvements aaaand a lot of post-its with ideas. I literally
              dream about writing post-its. Thank God that REM sleep still
              prevents me from doing that. The bad part is, that this whole
              Agile thing invaded my private life as well. It’s everywhere, not
              only in software development. Or is that, that bad? It is hard to
              navigate this world of Agile, so why not making it simpler, less
              mystical, less misinterpreted. My vision is:
            </p>
            <blockquote>stewardship means togetherness</blockquote>
            <p>
              The very reason for which I’ve created this place here. It should
              be a place where the practical side of things is far more present
              and prominent than just some abstract theory or yet another
              framework. If you like what you find here, share it, if not, just
              ignore :). enjoy your day!
            </p>
          </div>
        </article>
      </div>
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
